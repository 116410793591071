document.addEventListener('DOMContentLoaded', () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    let myForm = document.getElementById(e.target.id);

    myForm
      .querySelector('button[type="submit"] .icon')
      .classList.remove('hidden');

    let formData = new FormData(myForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        document
          .querySelector(`#${myForm.id}-success-alert`)
          .classList.remove('hidden');
      })
      .catch((error) => {
        console.log(error);
        document
          .querySelector(`#${myForm.id}-error-alert`)
          .classList.remove('hidden');
      })
      .finally(() => {
        myForm
          .querySelector('button[type="submit"] .icon')
          .classList.add('hidden');
      });
  };

  for (const form of document.querySelectorAll(
    '#contact-form, #quote-request-form'
  )) {
    form.addEventListener('submit', handleSubmit);
  }
});
